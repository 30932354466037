/* eslint-disable @typescript-eslint/naming-convention */
/**
 *  Widget main config
 *  @module Widgets
 *  @author Livescore <info@score-stats.com>
 *  @copyright 2021 livescore
 */

const conf = {
    lastMatches: {
        showInPage: false,
        availSports: [
            // enable sports for this type of widget
            'tennis',
            'soccer',
            'basketball',
            'handball',
            'rugby',
            'futsal',
            'ice-hockey',
            'field-hockey',
            'beach-soccer',
            'table-tennis',
            'beach-volley',
            'volleyball',
            'badminton',
            'american-football',
            'floorball',
        ],
    },


    bothTeamScore: {
        showInPage: true,
        path: 'both-team-to-score',
        height: 465,
        heightF: 600,
        availSports: [
            // enable sports for this type of widget
            'soccer',
            'futsal',
            'ice-hockey',
            'field-hockey',
            'beach-soccer',
            'floorball',
        ],
    },
    bothTeamScore2Goals: {
        showInPage: true,
        path: 'both-team-to-score-2goals',
        height: 235,
        heightF: 400,
        numMatches: 20,
        availSports: [
            'ice-hockey',
        ],
    },
    bothTeamScore3Goals: {
        showInPage: true,
        path: 'both-team-to-score-3goals',
        height: 235,
        heightF: 400,
        numMatches: 20,
        availSports: [
            'ice-hockey',
        ],
    },
    scoredEveryPeriod: {
        showInPage: true,
        path: 'scored-every-period',
        height: 235,
        heightF: 400,
        numMatches: 20,
        availSports: [
            'ice-hockey',
        ],
    },
    winEveryPeriod: {
        showInPage: true,
        path: 'win-every-period',
        height: 235,
        heightF: 400,
        numMatches: 20,
        availSports: [
            'ice-hockey',
        ],
    },

    willGameGoToShootout: {
        showInPage: true,
        path: 'will-go-to-shootout',
        height: 335,
        heightF: 500,
        numMatches: 50,
        availSports: [
            'ice-hockey',
        ],
    },
    underOver: {
        showInPage: true,
        path: 'under-over',
        height: 390,
        heightF: 420,
        availSports: [
            // enable sports for this type of widget
            'soccer',

        ],
        uo: {
            soccer: [0.5, 1.5, 2.5],

        },
    },
    underOver2: {
        showInPage: true,
        path: 'total-hockey',
        height: 345,
        heightF: 400,
        availSports: [
            // enable sports for this type of widget

            'ice-hockey',
        ],
        uo: {

            'ice-hockey': [3.5, 4.5],
        },
    },
    underOver3: {
        showInPage: true,
        path: 'total-hockey-3w',
        height: 475,
        heightF: 650,
        availSports: [
            // enable sports for this type of widget

            'ice-hockey',
        ],
        uo: {
            'ice-hockey': [3, 5],
        },
    },

    basketWinPrct: {
        showInPage: true,
        path: 'basket-win-prct',
        height: 550,
        heightF: 600,
        availSports: [
            // enable sports for this type of widget
            'basketball',
            'american-football',
        ],
    },
    spread: {
        showInPage: true,
        path: 'spread',
        height: 570,
        heightF: 550,
        availSports: [
            // enable sports for this type of widget
            'basketball',
            'ice-hockey',
        ],
    },
    highestScoringHalf: {
        showInPage: true,
        path: 'highest-scoring-half',
        height: 270,
        heightF: 250,
        availSports: [
            // enable sports for this type of widget
            'basketball',
            'american-football',
        ],
    },
    highestScoringQuater: {
        showInPage: true,
        path: 'highest-scoring-quater',
        height: 390,
        heightF: 450,
        availSports: [
            // enable sports for this type of widget
            'basketball',
            'american-football',
        ],
    },
    doubleResultPeriod: {
        showInPage: true,
        path: 'double-result-period',
        height: 390,
        heightF: 450,
        availSports: [
            // enable sports for this type of widget
            'basketball',
            'american-football',
        ],
    },
    winEveryQuater: {
        showInPage: true,
        path: 'win-every-quater',
        height: 270,
        heightF: 250,
        availSports: [
            // enable sports for this type of widget
            'basketball',
            'american-football',
        ],
    },
    winBothHalves: {
        showInPage: true,
        path: 'win-both-halves',
        height: 270,
        heightF: 250,
        availSports: [
            // enable sports for this type of widget
            'basketball',
            'american-football',
        ],
    },
    scoredEveryQuater: {
        showInPage: true,
        path: 'scored-every-quater',
        height: 270,
        heightF: 250,
        availSports: [
            // enable sports for this type of widget

            'american-football',
        ],
    },
    scoredEveryHalf: {
        showInPage: true,
        path: 'scored-every-half',
        height: 270,
        heightF: 250,
        availSports: [
            // enable sports for this type of widget

            'american-football',
        ],
    },


    totalPointsAmFootball: {
        showInPage: true,
        path: 'total-points-american-football',
        height: 300,
        heightF: 350,
        availSports: [
            // enable sports for this type of widget
            'american-football',
        ],
    },
    totalPoints: {
        showInPage: true,
        path: 'basketball-total-points',
        height: 300,
        heightF: 350,
        availSports: [
            // enable sports for this type of widget
            'basketball',

        ],
        tournamentsLong: [1940], // Id of tournaments with period of 12 min
        tournamentsLongLimits: [225.5, 111.5, 57.5],
        mapPeriods: {
            total: 'score_normal_time',
            '1half': ['score_period_1', 'score_period_2'],
            '2half': ['score_period_3', 'score_period_4'],
            '1q': 'score_period_1',
            '2q': 'score_period_2',
            '3q': 'score_period_3',
            '4q': 'score_period_4',
        },
    },
    totalPoints1Half: {
        showInPage: true,
        path: 'basketball-total-points-1sthalf',
        height: 300,
        heightF: 350,
        availSports: [
            // enable sports for this type of widget
            'basketball',
        ],
    },
    totalPoints2Half: {
        showInPage: true,
        path: 'basketball-total-points-2ndhalf',
        height: 300,
        heightF: 350,
        availSports: [
            // enable sports for this type of widget
            'basketball',
        ],
    },
    totalPoints1q: {
        showInPage: true,
        path: 'basketball-total-points-1q',
        height: 300,
        heightF: 350,
        availSports: [
            // enable sports for this type of widget
            'basketball',
        ],
    },
    totalPoints2q: {
        showInPage: true,
        path: 'basketball-total-points-2q',
        height: 300,
        heightF: 350,
        availSports: [
            // enable sports for this type of widget
            'basketball',
        ],
    },
    totalPoints3q: {
        showInPage: true,
        path: 'basketball-total-points-3q',
        height: 300,
        heightF: 350,
        availSports: [
            // enable sports for this type of widget
            'basketball',
        ],
    },
    totalPoints4q: {
        showInPage: true,
        path: 'basketball-total-points-4q',
        height: 300,
        heightF: 350,
        availSports: [
            // enable sports for this type of widget
            'basketball',
        ],
    },
    scoringPeriod: {
        showInPage: true,
        path: 'scoring-period',
        height: 345,
        heightF: 400,
        availSports: [
            // enable sports for this type of widget
            'ice-hockey',
        ],
    },
    scoringHalf: {
        showInPage: true,
        path: 'scoring-half',
        height: 300,
        heightF: 350,
        availSports: [
            // enable sports for this type of widget
            'soccer',
            'futsal',
        ],
    },
    winHalf: {
        showInPage: true,
        path: 'win-half',
        height: 300,
        heightF: 350,
        availSports: [
            // enable sports for this type of widget
            'soccer',
            'futsal',
        ],
    },
    twoWay: {
        showInPage: true,
        path: '2way',
        height: 360,
        heightF: 600,
        availSports: [
            // enable sports for this type of widget
            'tennis',
        ],
    },
    totalGames: {
        showInPage: true,
        path: 'total-games',
        height: 300,
        heightF: 500,
        availSports: [
            // enable sports for this type of widget
            'tennis',
        ],
    },
    doubleResult: {
        showInPage: true,
        path: 'double-result',
        height: 300,
        heightF: 500,
        availSports: [
            // enable sports for this type of widget
            'tennis',
        ],
    },

    setsWon1: {
        showInPage: true,
        path: 'sets-won-1period',
        height: 205,
        heightF: 350,
        availSports: [
            // enable sports for this type of widget
            'tennis',
        ],
    },
    setsWon2: {
        showInPage: true,
        path: 'sets-won-2period',
        height: 205,
        heightF: 350,
        availSports: [
            // enable sports for this type of widget
            'tennis',
        ],
    },
    bothToWinSet: {
        showInPage: true,
        path: 'both-to-win-set',
        height: 205,
        heightF: 350,
        availSports: [
            // enable sports for this type of widget
            'tennis',
        ],
    },
    noSetWon: {
        showInPage: true,
        path: 'no-set-won',
        height: 205,
        heightF: 350,
        availSports: [
            // enable sports for this type of widget
            'tennis',
        ],
    },
    gamesWon1: {
        showInPage: true,
        path: 'games-won-1period',
        height: 205,
        heightF: 350,
        availSports: [
            // enable sports for this type of widget
            'ice-hockey',
        ],
    },
    gamesWon2: {
        showInPage: true,
        path: 'games-won-2period',
        height: 205,
        heightF: 350,
        availSports: [
            // enable sports for this type of widget
            'ice-hockey',
        ],
    },
    gamesWon3: {
        showInPage: true,
        path: 'games-won-3period',
        height: 205,
        heightF: 350,
        availSports: [
            // enable sports for this type of widget
            'ice-hockey',
        ],
    },

    topScorer: {
        showInPage: true,
        path: 'goal-scorer',
        height: 395,
        heightF: 650,
        availSports: [
            // enable sports for this type of widget
            'soccer',
        ],
    },

    statsScorer: {
        showInPage: true,
        path: 'goal-scorer-stats',
        height: 595,
        heightF: 850,
        availSports: [
            // enable sports for this type of widget
            'soccer',
            'ice-hockey',
        ],
    },

    correctScore: {
        showInPage: true,
        path: 'correct-score',
        height: 375,
        heightF: 550,
        availSports: [
            // enable sports for this type of widget
            'soccer',
            'rugby',
            'futsal',
            'ice-hockey',
            'field-hockey',
            'beach-soccer',
            'floorball',
        ],
    },
    corners: {
        showInPage: true,
        path: 'corners',
        height: 320,
        heightF: 450,
        availSports: [
            // enable sports for this type of widget
            'soccer',
        ],
    },

    cards: {
        showInPage: true,
        path: 'cards',
        height: 330,
        heightF: 460,
        availSports: [
            // enable sports for this type of widget
            'soccer',
        ],
    },

    totalSets: {
        showInPage: true,
        path: 'total-sets',
        height: 320,
        heightF: 450,
        availSports: [
            // enable sports for this type of widget
            'tennis',
        ],
    },
    numberOfSets: {
        showInPage: true,
        path: 'number-of-sets',
        height: 500,
        heightF: 640,
        availSports: [
            // enable sports for this type of widget
            'tennis',
        ],
    },
    hadndicap2way: {
        showInPage: true,
        path: 'handicap-2way',
        height: 500,
        heightF: 640,
        availSports: [
            // enable sports for this type of widget
            'tennis',
        ],
    },
    dblChance: {
        showInPage: true,
        path: 'double-chance',
        height: 255,
        heightF: 400,
        availSports: [
            // enable sports for this type of widget
            'soccer',
            'basketball',
            'handball',
            'futsal',
            'ice-hockey',
            'field-hockey',
            'beach-soccer',
            'american-football',
            'floorball',
        ],
    },
    form: {
        showInPage: false,
        availSports: [
            // enable sports for this type of widget
            'soccer',
            'tennis',
            'basketball',
            'handball',
            'rugby',
            'futsal',
            'ice-hockey',
            'field-hockey',
            'beach-soccer',
            'american-football',
            'floorball',
        ],
    },
    avgGoals: {
        showInPage: true,
        path: 'avg-goals',
        height: 375,
        heightF: 650,
        availSports: [
            // enable sports for this type of widget
            'soccer',
            'basketball',
            'handball',
            'rugby',
            'futsal',
            'ice-hockey',
            'field-hockey',
            'beach-soccer',
            'american-football',
            'floorball',
        ],
    },
    hfft: {
        showInPage: false,
        path: 'hf-ft',
        availSports: [
            // enable sports for this type of widget
            'soccer',
            'handball',
            'rugby',
            'futsal',
            'bandy',
        ],
        periods: {
            // periods end time
            soccer: [45, 90],
            handball: [30, 60],
            rugby: [40, 80],
            futsal: [20, 40],
            bandy: [45, 90],
        },
    },
    goalScored: {
        showInPage: true,
        path: 'goal-scored',
        height: 375,
        heightF: 650,
        availSports: [
            // enable sports for this type of widget
            'soccer',
            'futsal',
            'ice-hockey',
            'field-hockey',
            'beach-soccer',
            'floorball',
        ],
    },
    matchFlash: {
        defaultDelay: 6000,
        extraDelay: 12000,
        showInPage: false,
        availSports: [
            // enable sports for this type of widget
            'tennis',
            'soccer',
            'basketball',
            'handball',
            'rugby',
            'futsal',
            'ice-hockey',
            'field-hockey',
            'beach-soccer',
            'table-tennis',
            'beach-volley',
            'volleyball',
            'badminton',
            'american-football',
            'floorball',
        ],
    },
};

export default conf;
