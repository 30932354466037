/* eslint-disable camelcase */
/**
 * League table interface [GET /api/table/{subtournamentId}]
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

export type TableSort = { index: number; direction: 'asc' | 'desc' };

export enum MatchType {
    UPCOMING = 'upcoming',
    PAST = 'past',
}

/**
 * Table subs
 */
export type TableSubs = 'ha';

/**
 * Overall , Half time Full time or Under Over
 */
export type TableSubsDataType = 'overal' | 'under_over' | 'half_full';

export interface LeagueTableInterface {
    tournament_id: number;
    tournament_name: string;
    sub_tournament_id: string;
    sub_tournament_name: string;
    season_id: number;
    season_name: string;
    columns: { short: string; name: string }[];
    tables: {
        name: string;
        isLive: boolean;
        data: { home: TableData[]; away: TableData[]; total: TableData[]; matrix: MatrixTable[][] };
    }[];
}

export interface TableData {
    place: number;
    participant_id: number;
    image: string;
    comment?: string | null;
    promotion: { name: string; color: string };
    name: string;
    columns: any[];
    matches: TableMatches[];
}

export interface TableMatches {
    type: MatchType;
    status: string;
    start_date: string;
    score: string;
    score_period_1?: string;
    score_normal_time?: string;
    home_team: string;
    away_team: string;
}

export interface Matrix {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    homeParticipant_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    awayParticipant_id: number;
}

export type MatrixTable = TableMatches & Matrix;
